/* ------------------------------------ FONTS ------------------------------------------------ */

/* ------------------------------------ VARIABLES -------------------------------------------- */

// SUPPORTED SCREEN SIZES
$extraLargeWidth: 1440px; // page size
$largeWidth: 1200px; // page size
$mediumWidth: 992px; // page size
$smallWidth: 768px; // iPad size
$extraSmallWidth: 576px; // Mobile Size
// COLORS
$primaryColor: #691ed8;
$errorColor: #f6001e;

$fontDefault: 'Frank Ruhl Libre';
$fontSecondery: 'Assistant';

$footerHeight: 83px;
$headerHeight: 85px;
$footerHeightMobile: 180px;
