@import '../../assets/style/variables.scss';

.thanks {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    position: relative;
    padding: 26px;
    padding-top: 289px;

    .thanks-fallen-image {
        width: 272px;
        height: 331px;
        position: absolute;
        top: 0;

        img {
            width: 100%;
            height: 100%;
            mask-image: url('../../assets/images/fallen/rectangle.svg');
            -webkit-mask-image: url('../../assets/images/fallen/rectangle.svg');
            mask-type: luminance;
            -webkit-mask-type: luminance;
            mask-size: cover;
            -webkit-mask-size: cover;
        }
    }

    .thanks-headlines {
        z-index: 1;
        margin-bottom: 65px;

        .thanks-text {
            height: 52px;
            opacity: 0.5;
            font-family: $fontSecondery;
            font-size: 40px;
            letter-spacing: -1.33px;
            color: #ffffff;
        }

        .thanks-fallen-info {
            min-height: 97px;
            font-family: $fontDefault;
            font-size: 75px;
            font-weight: bold;
            letter-spacing: -2.5px;
            color: #ffffff;

            span {
                font-weight: 300;
                color: rgba(255, 255, 255, 0.8);
            }
        }
    }

    .thanks-buttons {
        margin-bottom: 36px;

        button {
            padding: 0;
            display: block;
            width: 554px;
            min-height: 40px;
            background-color: rgba(255, 255, 255, 0.1);
            font-family: $fontSecondery;
            font-size: 20px;
            font-weight: 600;
            letter-spacing: -0.67px;
            color: #ffffff;

            &:last-child {
                margin-top: 20px;
                background-color: rgba(255, 188, 0, 0.59);
            }
        }
    }

    .thanks-social {
        display: flex;
        justify-content: center;
        align-items: center;

        span {
            font-family: $fontSecondery;
            font-size: 15px;
            font-weight: 600;
            letter-spacing: -0.5px;
            color: #ffffff;
        }
    }
}

.ltr {
    .thanks {
        .thanks-social {
            direction: ltr;
        }
    }
}

@media only screen and (max-width: $smallWidth) {
    .thanks {
        padding-top: 173px;

        .thanks-fallen-image {
            width: 218px;
            height: 264px;
        }

        .thanks-headlines {
            margin-bottom: 35px;

            .thanks-text {
                height: 62px;
                opacity: 0.5;
                font-size: 25px;
                line-height: 1.24;
                letter-spacing: -0.83px;
            }

            .thanks-fallen-info {
                min-height: 58px;
                font-size: 45px;
                letter-spacing: -1.5px;
            }
        }

        .thanks-buttons {
            width: 100%;
            margin-bottom: 28px;

            button {
                width: 100%;

                &:last-child {
                    margin-top: 15px;

                }
            }
        }
    }
}