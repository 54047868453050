/* ------------------------------------ IMPORTS -------------------------------------------- */
@import "assets/style/fonts.scss";
@import "assets/style/normalize.scss";
@import "assets/style/variables.scss";

html {
  scroll-behavior: smooth;
  width: 100%;
  height: 100%;
  min-height: 100%;
}

body {
  font-family: $fontDefault;
  direction: rtl;
  margin: 0;
  color: black;
  width: 100%;
  height: 100%;
  min-height: 100%;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

button {
  border: none;
  background: transparent;

  &:focus {
    outline: none;
  }
}

select,
input {
  &:focus {
    outline: none;
  }
}

a {
  text-decoration: none;
}

a,
button,
select,
option,
input[type="radio"],
input[type="checkbox"] {
  cursor: pointer;
}

a {
  color: inherit;

  &:active,
  &:visited {
    color: inherit;
  }
}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

#root {
  width: 100%;
  height: 100%;
  min-height: 100%;
}

.layout {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  &.loading {
    padding-top: 0;

    .layout-route {
      min-height: 100vh;
    }
  }

  &.default-page {
    background-image: url("./assets/images/backgrounds/default.png");
  }

  &.fallen-page {
    background-image: url("./assets/images/backgrounds/second-background.jpg");
  }
}

.layout-route {
  min-height: calc(100vh - #{$headerHeight} - #{$footerHeight});
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.show-tablet {
  display: none;
}

@media only screen and (max-width: $smallWidth) {
  .show-tablet {
    display: block;
  }

  .hide-tablet {
    display: none;
  }

  .layout {
    &.default-page {
      background-image: url("./assets/images/backgrounds/default_mobile.png");
    }
  }

  .layout-route {
    min-height: calc(100vh - #{$headerHeight} - #{$footerHeightMobile});
  }
}
